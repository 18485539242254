.product-card {
  .Polaris-Card + .Polaris-Card {
    margin-top: 0px;
  }

  &__stock-icon {
    display: flex;

    .Polaris-Icon {
      margin-right: 0.25rem;
      height: 1rem;
      width: 1rem;
    }
  }

  &__header {
    position: relative;

    &__options {
      position: absolute;
      width: 90%;
      top: 5%;
      left: 5%;

      &__icons span .Polaris-Icon {
        margin-bottom: 0.5rem;

        & svg:hover {
          fill: green;
        }
      }

      &__icons span:nth-child(2) .Polaris-Icon {
        & svg:hover {
          fill: purple;
        }
      }

      &__icons span:nth-child(3) .Polaris-Icon {
        & svg:hover {
          fill: blue;
        }
      }
    }
  }

  &__image {
    height: 210px;
    margin-bottom: 0px;
    margin-top: 0px;

    @media screen and (max-width: 55.9375em) {
      height: 210px;
    }

    @media screen and (max-width: 25.9375em) {
      height: 275px;
    }

    img {
      padding: 1rem 1rem 0px;
    }
  }

  &__no-image {
    display: flex;
    height: 210px;
  }

  &__prices {
    margin: 0.5rem 0;

    .Polaris-Stack__Item {
      width: 50%;

      .Polaris-Tag {
        width: 100%;
        justify-content: center;
        padding: 0.4rem 0;
        background-color: #f5f5f9;

        .price--yellow {
          color: #dcac15;
        }

        .price--blue {
          color: #1d5ae1;
        }
      }
    }
  }

  .Polaris-Card {
    .Polaris-Heading {
      max-width: 215px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .Polaris-Card__Section {
      padding-top: 0px;
      padding-bottom: 0.5rem;

      .product-card__brand,
      .product-card__supplier,
      .product-card__shipping {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .product-card__brand,
      .product-card__supplier {
        max-width: 215px;
      }
      .product-card__shipping {
        max-width: 245px;
        div {
          span {
            display: flex;
            .Polaris-Icon {
              display: inline-block;
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
}
